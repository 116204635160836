import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoiceButtonsModule } from './extensions/cd2cea82cf5a91dfb182bb353ea25c8d0ed6e357d1da0c6471d6b87df3af463f/invoice-buttons.module';
import { PackingListButtonModule } from './extensions/3ba1f19149bec7e8e882b6a908929d5db612f0fd4680c0eb08d8a8b6dc727633/packing-list-button.module';

import SharedProviders_0_0 from './extensions/cd2cea82cf5a91dfb182bb353ea25c8d0ed6e357d1da0c6471d6b87df3af463f/providers';
import SharedProviders_1_0 from './extensions/21d4404d5960a48be673c07ace9e1648ef3c6dd5984cda2a3aa4bce558fc7778/mark-shipped-button.module';
import SharedProviders_1_1 from './extensions/21d4404d5960a48be673c07ace9e1648ef3c6dd5984cda2a3aa4bce558fc7778/label-button.module';
import SharedProviders_1_2 from './extensions/21d4404d5960a48be673c07ace9e1648ef3c6dd5984cda2a3aa4bce558fc7778/manifest-button.module';
import SharedProviders_1_3 from './extensions/21d4404d5960a48be673c07ace9e1648ef3c6dd5984cda2a3aa4bce558fc7778/customs-button.module';
import SharedProviders_2_0 from './extensions/fa7c6b8d69f54965913c2e5eb76c937dcc88b29347a87dda94b05772245039f1/providers';
import SharedProviders_3_0 from './extensions/5b355a46194ddcb51bf1b3d934529adef519d9ea6dc8cc16719f77866a0aa71f/providers';
import SharedProviders_4_0 from './extensions/9323bbc95c48fffd09f6d2702be73a390a78fdc6cbf3b7b2b0c3938489a9e9ff/providers';
import SharedProviders_6_0 from './extensions/3c2acf4279506524d514186cf0e283e30d5d1b71af322eef9bc50f1ca39dcf52/providers';


@NgModule({
    imports: [CommonModule, InvoiceButtonsModule, PackingListButtonModule],
    providers: [...SharedProviders_0_0, ...SharedProviders_1_0, ...SharedProviders_1_1, ...SharedProviders_1_2, ...SharedProviders_1_3, ...SharedProviders_2_0, ...SharedProviders_3_0, ...SharedProviders_4_0, ...SharedProviders_6_0],
})
export class SharedExtensionsModule {}
