import { NgModule } from '@angular/core';
import { SharedModule, addActionBarItem, ActionBarContext } from '@vendure/admin-ui/core';
import { packingListQuery } from './queries.graphql';
import { PackingListQuery, PackingListQueryVariables } from './generated/graphql';
import { map, switchMap } from "rxjs/operators";

function openBase64Pdf(base64Pdf) {
    // Convert the Base64 string to binary data
    const binaryData = atob(base64Pdf);

    // Create a Uint8Array to hold the binary data
    const byteArray = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
        byteArray[i] = binaryData.charCodeAt(i);
    }

    // Create a Blob from the byte array
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    // Generate a temporary object URL for the Blob
    const url = URL.createObjectURL(blob);

    // Open the URL in a new browser tab
    window.open(url);

    // Optional: Revoke the object URL after a few minutes to free resources
    setTimeout(() => URL.revokeObjectURL(url), 60000);
}


@NgModule({
    imports: [
        SharedModule
    ],
    providers: [
        addActionBarItem({
            id: "order-print-packing-list",
            label: "Packing List",
            locationId: "order-detail",
            buttonStyle: "outline",
            onClick: (event: MouseEvent, context: ActionBarContext) => {
                const view = event.view;
                const id = context.route.snapshot.params.id;

                if (!id || !view) {
                    return;
                }

                context.dataService.query<PackingListQuery, PackingListQueryVariables>(packingListQuery, {
                    orderId: id
                }).mapSingle(packingList => {
                    openBase64Pdf(packingList.packingList.base64);
                }).subscribe();

            },
            requiresPermission: "SuperAdmin",
            buttonState: context => {
                try {
                    window['userIdentifier'] = context.dataService.auth.currentUser().ref.getCurrentResult().data;
                } catch (ex) {
                    // noop
                }

                return context.route.data.pipe(
                    switchMap(data => data.detail.entity),
                    map((order: any) => ({
                        disabled: order.state === 'AddingItems',
                        visible: true,
                    })),
                );
            },
        }),
    ]
})

// @ts-ignore
export class PackingListButtonModule { }