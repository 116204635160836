import { addActionBarDropdownMenuItem } from '@vendure/admin-ui/core';
import { PurgeFeaturedProductsCacheMutation } from "./generated/graphql"
import { purgeMutation } from './mutations.graphql';

export default [
    addActionBarDropdownMenuItem({
        id: 'purge-featured-products-cache',
        locationId: 'product-list',
        label: 'Purge Featured Products Cache',
        icon: 'refresh',
        requiresPermission: 'SuperAdmin',
        onClick: (ev, ctx) => {
            ctx.dataService.mutate<PurgeFeaturedProductsCacheMutation>(purgeMutation).subscribe(resp => {
                if (resp.purgeFeaturedProductsCache.success) {
                    ctx.notificationService.success("Cache has been purged");
                } else {
                    ctx.notificationService.error("Unable to purge cache");
                }
            });
        }
    }),
];